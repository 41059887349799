import { gql } from '@urql/core';

export const SEND_MESSAGE_MUTATION = gql`
    mutation SendMessage(
        $advertId: Int64!
        $email: String!
        $name: String!
        $phone: String
        $text: String!
        $sendSimilar: Boolean
        $financeLead: Boolean
        $city: String
        $utm: Utm
        $leadFormType: String
        $leadFormTool: String
        $experiment: String
    ) {
        sendMessage(
            input: {
                advertId: $advertId
                email: $email
                name: $name
                phone: $phone
                text: $text
                sendSimilar: $sendSimilar
                financeLead: $financeLead
                city: $city
                utm: $utm
                leadFormType: $leadFormType
                leadFormTool: $leadFormTool
                experiment: $experiment
            }
        ) {
            __typename
            ... on MessageResponse {
                financeLeadResponse {
                    ... on SendLeadCollectorSuccess {
                        success
                    }
                    ... on SendLeadCollectorError {
                        error
                    }
                }
            }
        }
    }
`;
