import { CONTACT_FORM_DATA_STORAGE_KEY } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormStorage';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { setStorageItemValue } from '@domains/shared/helpers/storage';
import type { CurrentUser } from '@lib/pages/user';

export const prefillContactData = (
    user: CurrentUser | null,
    details: Pick<AdContactForm, 'name' | 'email' | 'phone'>,
): void => {
    if (user) {
        return;
    }

    const { name, email, phone } = details;
    setStorageItemValue(CONTACT_FORM_DATA_STORAGE_KEY, { name, phone, email });
};
