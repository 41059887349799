import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import MoreInfo from '@nexus/lib-react/dist/core/Icon/icons/default/MoreInfo';

export const Container = styled.div`
    margin-bottom: ${theme.space['16']};
    font-family: ${theme.fonts.family};
`;
export const StyledIcon = styled(MoreInfo)`
    margin-right: ${theme.space['16']};
`;
