import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';

export interface AdditionalFinanceParameters {
    utm: { source: string; medium: string; campaign: string };
    leadFormType: string;
    leadFormTool: string;
    experiment: string | null;
}

export const useAdditionalFinanceParameters = (): AdditionalFinanceParameters => {
    const { getExperimentVariant } = useExperiments();
    const experimentVariant = getExperimentVariant(EXPERIMENT.eure29465);

    return {
        utm: { source: 'otodom', medium: 'contactForm', campaign: 'checkbox' },
        leadFormType: 'other',
        leadFormTool: 'checkbox',
        experiment: experimentVariant ? `eure-29465@${experimentVariant}` : null,
    };
};
