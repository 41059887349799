import { Container } from '@domains/ad/nexusComponents/ContactForm/components/FinanseLeadField/FinanceLeadMoreInfo.theme';
import { parseFinanceLeadDescription } from '@domains/ad/nexusComponents/ContactForm/helpers/parseFinanceLeadDescription';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

interface Props {
    shouldShowAsExpandableElement?: boolean;
}

export const FinanceLeadMoreInfo = ({ shouldShowAsExpandableElement }: Props): JSX.Element => {
    const [t] = useTranslations();
    const content = parseFinanceLeadDescription(t('frontend.ad.contact-form.finance-privacy-policy-new'));

    return (
        <Container
            shouldShowAsExpandableElement={shouldShowAsExpandableElement}
            dangerouslySetInnerHTML={{ __html: content }}
            data-testid="contact-form-financeLead-more-info"
            data-cy="contact-form.finance-privacy-policy.tooltip"
        ></Container>
    );
};
