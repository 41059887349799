import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { FormControlLabel } from '@nexus/lib-react/dist/core/FormControl';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';

const HIDE_EMPTY_DIV = css`
    &:empty {
        display: none;
    }
`;

export const InputsWraper = styled.div`
    display: flex;
    width: 100%;

    & > div:nth-last-of-type(1) {
        flex-grow: 1;
    }
`;

export const CustomLabel = styled(FormControlLabel)`
    ${HIDE_EMPTY_DIV}

    & ~ div {
        ${HIDE_EMPTY_DIV}
    }
`;

export const StyledCountryCodeInput = styled(TextInput)`
    width: 68px;
    margin-bottom: ${theme.space['8']};

    & input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        color: ${theme.colors['text-global-secondary']};
    }
`;

export const StyledPhoneNumberInput = styled(TextInput)`
    width: 100%;
    margin-bottom: ${theme.space['8']};

    & input {
        padding-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;
