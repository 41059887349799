import type { MessageSubjectOption } from '@domains/ad/types/contactForm';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useMemo } from 'react';

interface MessageSubjectOptions {
    allOptions: MessageSubjectOption[];
    visibleOptions: MessageSubjectOption[];
}

export const useMessageSubjectOptions = (): MessageSubjectOptions => {
    const [t] = useTranslations();

    const options = useMemo(
        () => [
            {
                label: t('frontend.ad.contact-form-message-subject.more-info'),
                value: t('frontend.ad.contact-form-message-subject.more-info'),
                event: 'more-info',
                type: 'more_info',
                visible: true,
            },
            {
                label: t('frontend.ad.contact-form-message-subject.suggest-price'),
                value: t('frontend.ad.contact-form-message-subject.suggest-price'),
                event: 'suggest-price',
                type: 'suggest_price',
                visible: true,
            },
            {
                label: t('frontend.ad.contact-form-message-subject.ask-about-price'),
                value: t('frontend.ad.contact-form-message-subject.ask-about-price'),
                event: 'ask-for-price',
                type: 'ask_for_price',
                visible: false,
            },
            {
                label: t('frontend.ad.contact-form-message-subject.schedule-visit'),
                value: t('frontend.ad.contact-form-message-subject.schedule-visit'),
                event: 'schedule-visit',
                type: 'schedule_visit',
                visible: true,
            },
            {
                label: t('frontend.ad.contact-form-message-subject.other-subject'),
                value: t('frontend.ad.contact-form-message-subject.other-subject'),
                event: 'other',
                type: 'other',
                visible: true,
            },
        ],
        [t],
    );

    return {
        allOptions: options,
        visibleOptions: options.filter((option) => option.visible),
    };
};
