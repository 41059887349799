import { ContactFormContent } from '@domains/ad/nexusComponents/ContactForm/components/ContactFormContent/ContactFormContent';
import { ContactFormSuccess } from '@domains/ad/nexusComponents/ContactForm/components/ContactFormSuccess/ContactFormSuccess';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import type { Ad } from '@domains/ad/types/Ad';
import type { JSX } from 'react';
import { useContext } from 'react';

interface Props {
    ad: Ad;
    showAsModal?: boolean;
}

export const ContactForm = ({ ad, showAsModal }: Props): JSX.Element => {
    const { state } = useContext(ContactFormContext);

    return (
        <div data-cy="contact-form">
            {state === 'form' ? (
                <ContactFormContent ad={ad} showAsModal={showAsModal} />
            ) : (
                <ContactFormSuccess id={ad.id} />
            )}
        </div>
    );
};
