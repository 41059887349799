import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { H4, P3 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    padding: ${theme.space['24']};
    background-color: ${theme.colors['background-brand-secondary-light']};

    @media ${theme.mediaQueries.lgMin} {
        height: 600px;
    }
`;

export const Icon = styled.img`
    width: ${theme.sizes[128]};
    height: ${theme.sizes[128]};
`;

export const Heading = styled(H4)`
    margin: ${theme.space['24']} ${theme.space['0']};
    font-weight: ${theme.fontWeights.bold};
`;

export const SaveAdWrapper = styled.div`
    margin-bottom: ${theme.space['16']};
    text-align: center;
`;

export const SaveAdInfo = styled(P3)`
    margin-bottom: ${theme.space['24']};
    text-align: center;
`;
