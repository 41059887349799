import type { JSX } from 'react';

import { AvatarPicture, Container } from './SellerAvatar.theme';

interface Props {
    url: string | null;
}

export const SellerAvatar = ({ url }: Props): JSX.Element | null => {
    if (!url) {
        return null;
    }

    return (
        <Container data-testid="seller-avatar">
            <AvatarPicture url={url}></AvatarPicture>
        </Container>
    );
};
