import { EXPERIMENT } from '@domains/ad/consts/experiment';
import {
    ErrorMessage,
    StyledTextArea,
} from '@domains/ad/nexusComponents/ContactForm/components/MessageField/MessageField.theme';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { getDefaultMessage } from '@domains/ad/nexusComponents/ContactForm/helpers/getDefaultMessage';
import type { Ad } from '@domains/ad/types/Ad';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { FormControl } from '@nexus/lib-react/dist/core/FormControl';
import type { ChangeEvent, JSX } from 'react';
import { useContext, useEffect, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
    ad: Ad;
}

export const MessageField = ({ ad }: Props): JSX.Element => {
    const [t] = useTranslations();

    const { isVariantEnabled } = useExperiments();
    const isEURE29876VariantB = isVariantEnabled(EXPERIMENT.eure29876, 'b');

    const { trackEvent } = useTracking();
    const { setTrackingData, trackingData } = useContext(ContactFormContext);
    const [defaultMessage, setDefaultMessage] = useState('');
    const {
        register,
        trigger,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useFormContext<AdContactForm>();
    const {
        field: { onChange },
    } = useController({ control, name: 'message' });

    const messageWatched = watch('message');
    const messageSubjectWatched = watch('messageSubject');

    const handleBlur = (): void => {
        trigger('message').then(() => {
            trackEvent(errors.message ? 'reply_message_text_error' : 'reply_message_text_valid', trackingData);
        });
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        onChange(event.target.value);
        setTrackingData({
            ...trackingData,
            message_detail: messageSubjectWatched.type === 'other' ? 'custom_message' : 'changed_predefined_message',
        });
    };

    useEffect(() => {
        if (isEURE29876VariantB) {
            return;
        }

        const message = getDefaultMessage({ ad, messageSubjectType: messageSubjectWatched?.type, t });
        setValue('message', message);

        // Update 'defaultMessage' to trigger FormControl re-render with the new key, ensuring
        // the character count resets accurately without interfering with text input.
        setDefaultMessage(message);
    }, [messageSubjectWatched, ad, t, setValue, isEURE29876VariantB]);

    return (
        <>
            <FormControl
                key={defaultMessage}
                inputComponent={StyledTextArea}
                inputProps={{
                    ...register('message', {
                        required: t('frontend.ad.contact-form.text-required-error'),
                    }),
                    value: messageWatched,
                    placeholder: isEURE29876VariantB ? t('frontend.ad.contact-form.field-placeholder-text') : undefined,
                    validationStatus: errors?.message ? 'hasError' : undefined,
                    dataAttributes: { testid: 'frontend.ad.contact-form.field-text' },
                    showSymbolsCount: false,
                    maxLength: 2000,
                    rows: 6,
                    onBlur: handleBlur,
                    onChange: handleChange,
                }}
                aria-label={t('frontend.ad.contact-form.field-placeholder-text')}
            />
            {errors?.message?.message ? <ErrorMessage>{errors.message?.message}</ErrorMessage> : null}
        </>
    );
};
