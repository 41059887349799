import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Textarea } from '@nexus/lib-react/dist/core/Textarea';
import { P4 } from '@nexus/lib-react/dist/core/Typography';

export const StyledTextArea = styled(Textarea)`
    width: 100%;
    margin: ${theme.space['8']} ${theme.space['0']} ${theme.space[4]} ${theme.space['0']};

    & + div {
        justify-content: right;
    }
`;

export const ErrorMessage = styled(P4)`
    color: ${theme.colors['text-global-error']};
`;
