import styled from '@emotion/styled';

export const Container = styled.div`
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
`;

export const AvatarPicture = styled.div<{ url: string }>`
    width: 100%;
    height: 100%;
    background-image: url('${({ url }): string => url}');
    background-position: center;
    background-size: cover;
`;
