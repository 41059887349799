import type { Ad } from '@domains/ad/types/Ad';

const SELLER_TYPE: Record<string, string> = {
    private: 'frontend.ad.contact-form.ad-type-private',
    privateBusiness: 'frontend.ad.contact-form.ad-type-business',
    agency: 'frontend.ad.contact-form.ad-type-business',
    developer: 'frontend.ad.contact-form.ad-type-developer',
} as const;

export const getSellerTypeLabel = (ad: Ad): string => {
    const { contactDetails, owner, advertiserType } = ad;

    if (contactDetails.type === 'private' && advertiserType === 'business') {
        return SELLER_TYPE.privateBusiness;
    }

    return SELLER_TYPE[owner.type];
};
