interface Input {
    isFinanceLeadChecked?: boolean;
    advertSource?: string;
    isEURE28875VariantB?: boolean;
}

export const isContactFormPhoneNumberRequired = ({
    isEURE28875VariantB,
    isFinanceLeadChecked,
    advertSource,
}: Input): boolean => {
    return !!(isFinanceLeadChecked || advertSource === 'urn:partner:obidointegration' || isEURE28875VariantB);
};
