import { AD_CONFIG } from '@config/ad/adConfig';
import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { usePhoneRevealStatistics } from '@lib/tracking/usePhoneRevealStatistics';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import Call from '@nexus/lib-react/dist/core/Icon/icons/default/Call';
import type { JSX } from 'react';
import { useContext, useState } from 'react';

import { PartialNumber, PhoneNumberLink, PhoneNumberWrapper } from './PhoneNumber.theme';

interface Props {
    adId: number;
    phoneNumber: string;
    trackingData?: Record<string, unknown>;
}

export const PhoneNumber = ({ adId, phoneNumber, trackingData }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const [shouldShowPhone, setShouldShowPhone] = useState(false);
    const { updateShowPhoneStatistics } = usePhoneRevealStatistics();
    const { isDesktop } = useContext(RWDContext);
    const { shouldPartiallyShowPhoneNumber, shouldUseCallCopy } = AD_CONFIG.contactForm;

    const { isVariantEnabled } = useExperiments();
    const isEURE27714VariantB = isVariantEnabled(EXPERIMENT.eure27714, 'b');
    const isEURE27714VariantC = isVariantEnabled(EXPERIMENT.eure27714, 'c');

    // Note: If Variant B is the winner, 'shouldUseCallCopy' won't be needed anymore.
    // Translations have to be handled via phrase as usual.
    const isCallPhoneNumberCopyVariantB = isEURE27714VariantB && shouldUseCallCopy && isDesktop;
    const isFirstThreeDigitsVariantC = isEURE27714VariantC && shouldPartiallyShowPhoneNumber && isDesktop;
    const isVariantBOrC = isCallPhoneNumberCopyVariantB || isFirstThreeDigitsVariantC;

    const firstThreePhoneNumberDigits = phoneNumber.replace(/\s/g, '').slice(0, 3);

    const handleShowPhoneClick = (): void => {
        setShouldShowPhone(true);
        trackEvent('reply_phone_show', trackingData);
        updateShowPhoneStatistics({ advertId: adId });
    };

    const handlePhoneNumberClick = (): void => {
        trackEvent('reply_phone_call', trackingData);
    };

    const PhoneNumberExperimentComponent = (
        <>
            {isCallPhoneNumberCopyVariantB && <Call size="icon-small" />}
            {isFirstThreeDigitsVariantC && !shouldShowPhone && (
                <PartialNumber>{firstThreePhoneNumberDigits}</PartialNumber>
            )}
            {shouldShowPhone ? (
                <PhoneNumberLink href={`tel:${phoneNumber}`} onClick={handlePhoneNumberClick}>
                    {phoneNumber}
                </PhoneNumberLink>
            ) : (
                <Button variant="tertiary" size="small" onClick={handleShowPhoneClick}>
                    {isCallPhoneNumberCopyVariantB && t('frontend.shared.phone-number.call-phone-number-button')}
                    {isFirstThreeDigitsVariantC && t('frontend.shared.phone-number.reveal-phone-number-button')}
                </Button>
            )}
        </>
    );

    const PhoneNumberDefaultComponent = (
        <>
            <Call size="icon-small" />
            {shouldShowPhone ? (
                <PhoneNumberLink href={`tel:${phoneNumber}`} onClick={handlePhoneNumberClick}>
                    {phoneNumber}
                </PhoneNumberLink>
            ) : (
                <Button
                    variant="tertiary"
                    size="small"
                    onClick={handleShowPhoneClick}
                    data-cy="phone-number.show-full-number-button"
                >
                    {t('frontend.shared.phone-number.reveal-phone-number-button')}
                </Button>
            )}
        </>
    );

    return (
        <PhoneNumberWrapper data-testid="phone-number">
            {isVariantBOrC ? PhoneNumberExperimentComponent : PhoneNumberDefaultComponent}
        </PhoneNumberWrapper>
    );
};
