import {
    Container,
    StyledIcon,
} from '@domains/ad/nexusComponents/ContactForm/components/PreviousContactInfo/PreviousContactInfo.theme';
import { PREVIOUS_CONTACT_STORAGE_KEY } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormStorage';
import { getDaysDifference } from '@domains/ad/nexusComponents/ContactForm/helpers/getDaysDifference';
import { getStorageItemValue } from '@domains/shared/helpers/storage';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { type Translator, useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import { Banner } from '@nexus/lib-react/dist/core/Banner';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';

interface Props {
    adId: number;
}

type SavedContactDates = Record<string, number>;

const getTranslation = (lang: Locale, t: Translator, lastContactDate: number): string => {
    const { days, formattedDate } = getDaysDifference(lang, lastContactDate);

    if (days > 30) {
        return t('frontend.ad.contact-form.notification-on-date', { date: formattedDate });
    }
    if (days < 1) {
        return t('frontend.ad.contact-form.notification-today');
    }
    if (days < 2) {
        return t('frontend.ad.contact-form.notification-yesterday');
    }

    return t('frontend.ad.contact-form.notification-n-days-ago', { days: `${days}` });
};
export const PreviousContactInfo = ({ adId }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const [savedContactDates, setSavedContactDates] = useState<SavedContactDates>({});

    useEffect(() => {
        setSavedContactDates((getStorageItemValue(PREVIOUS_CONTACT_STORAGE_KEY) as SavedContactDates) || {});
    }, []);

    const isPreviousContact = typeof savedContactDates[adId] === 'number';

    if (!isPreviousContact) {
        return null;
    }

    return (
        <Container data-testid="frontend.ad.contact-form.previous-contact-banner">
            <Banner variant="neutral">
                <StyledIcon size="icon-large" />
                <P3>{getTranslation(lang, t, savedContactDates[adId])}</P3>
            </Banner>
        </Container>
    );
};
