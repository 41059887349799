import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import { P4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div<{ showAsModal?: boolean }>`
    width: 100%;
    padding: ${({ showAsModal }): CSSProperties['padding'] =>
        showAsModal ? `${theme.space['0']} ${theme.space['26']} ${theme.space['26']}` : theme.space['26']};
    background-color: ${theme.colors.$01_white};
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.space['16']};
`;

export const ErrorMessage = styled(P4)`
    margin-bottom: ${theme.space['8']};
    color: ${theme.colors['text-global-error']};
`;

export const StyledSubmitButton = styled(Button)`
    width: 100%;
    margin-top: ${theme.space['16']};
`;

export const StyledDivider = styled(Divider)`
    margin: ${theme.space['8']} ${theme.space['0']};
`;
