import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { getLocaleFromLangCode } from '@domains/shared/helpers/getLocaleFromLangCode';
import type { Locale } from '@lib/i18n/types/locale';

const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_DAY = 86400 * 1000;

interface Result {
    days: number;
    formattedDate: string;
}

const { tz } = getPublicEnvConfig();

export const getDaysDifference = (lang: Locale, lastContactDate: number): Result => {
    const currentDate = new Date();
    const savedDate = new Date(lastContactDate);
    let days = Math.floor((currentDate.getTime() - lastContactDate) / MILLISECONDS_IN_DAY);
    const currentDateInSeconds =
        currentDate.getHours() * SECONDS_IN_HOUR + currentDate.getMinutes() * SECONDS_IN_MINUTE;
    const savedDateInSeconds = savedDate.getHours() * SECONDS_IN_HOUR + savedDate.getMinutes() * SECONDS_IN_MINUTE;

    if (savedDateInSeconds > currentDateInSeconds) {
        days += 1;
    }

    return { days, formattedDate: savedDate.toLocaleDateString(getLocaleFromLangCode(lang), { timeZone: tz }) };
};
