import { ErrorMessage } from '@domains/ad/nexusComponents/ContactForm/components/ContactFormContent/ContactFormContent.theme';
import {
    CustomLabel,
    StyledTextInput,
} from '@domains/ad/nexusComponents/ContactForm/components/TextField/TextField.theme';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { FormControl } from '@nexus/lib-react/dist/core/FormControl';
import type { JSX } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import type { RegisterOptions } from 'react-hook-form/dist/types/validator';

type FieldName = 'name' | 'email';

interface Props {
    name: FieldName;
    validationRules: RegisterOptions;
    defaultValue?: string;
    trackingData: Record<string, unknown>;
    shouldDisableInput?: boolean;
}

const LABEL: Record<FieldName, string> = {
    name: 'frontend.ad.contact-form.field-placeholder-name',
    email: 'frontend.ad.contact-form.field-placeholder-email',
} as const;

const DATA_TEST_ID: Record<FieldName, string> = {
    name: 'frontend.ad.contact-form.field-name',
    email: 'frontend.ad.contact-form.field-email',
} as const;

export const TextField = ({
    name,
    validationRules,
    defaultValue,
    trackingData,
    shouldDisableInput,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const {
        register,
        trigger,
        watch,
        setValue,
        formState: { errors },
    } = useFormContext<AdContactForm>();

    const valueWatched = watch(name);

    useEffect(() => {
        if (defaultValue) {
            setValue(name, defaultValue);
        }
    }, [setValue, name, defaultValue]);

    const handleBlur = (): void => {
        trigger(name).then(() => {
            trackEvent(errors[name] ? `reply_message_${name}_error` : `reply_message_${name}_valid`, trackingData);
        });
    };

    return (
        <>
            <FormControl
                labelComponent={CustomLabel}
                inputComponent={StyledTextInput}
                inputProps={{
                    ...register(name, validationRules),
                    placeholder: `${t(`${LABEL[name]}`)}*`,
                    validationStatus: errors?.[name] ? 'hasError' : undefined,
                    dataAttributes: { testid: DATA_TEST_ID[name] },
                    value: shouldDisableInput ? defaultValue : valueWatched,
                    onBlur: handleBlur,
                    disabled: shouldDisableInput,
                }}
                aria-label={t(LABEL[name])}
            />
            {errors?.[name]?.message ? <ErrorMessage>{errors[name]?.message}</ErrorMessage> : null}
        </>
    );
};
