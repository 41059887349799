import { EXPERIMENT } from '@domains/ad/consts/experiment';
import {
    CheckboxLabel,
    CheckboxWrapper,
    Container,
    Heading,
    StyledPopover,
    StyledQuestionMark,
} from '@domains/ad/nexusComponents/ContactForm/components/FinanseLeadField/FinanceLeadField.theme';
import { FinanceLeadMoreInfo } from '@domains/ad/nexusComponents/ContactForm/components/FinanseLeadField/FinanceLeadMoreInfo';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { CheckboxInput } from '@nexus/lib-react/dist/core/CheckboxInput';
import { FormControl } from '@nexus/lib-react/dist/core/FormControl';
import { P4 } from '@nexus/lib-react/dist/core/Typography';
import type { ChangeEvent, JSX } from 'react';
import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

interface Props {
    trackingData: Record<string, unknown>;
    shouldUsePopover: boolean;
}

export const LABEL_VARIANTS = {
    variantA: 'frontend.ad.contact-form.allow-send-finance-lead-remt-615',
    variantB: 'frontend.ad.contact-form.finance-lead-label-variant-b',
    variantC: 'frontend.ad.contact-form.finance-lead-label-variant-c',
};

export const FinanceLeadField = ({ trackingData, shouldUsePopover }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { register, trigger, control } = useFormContext<AdContactForm>();
    const {
        field: { onChange },
    } = useController({ control, name: 'financeLead' });
    const [shouldShowExpandableMoreInfo, setShouldShowExpandableMoreInfo] = useState(false);
    const { isVariantEnabled } = useExperiments();
    const isEURE29465VariantB = isVariantEnabled(EXPERIMENT.eure29465, 'b');
    const isEURE29465VariantC = isVariantEnabled(EXPERIMENT.eure29465, 'c');
    const labelVariant = isEURE29465VariantB ? 'variantB' : isEURE29465VariantC ? 'variantC' : 'variantA';

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        trigger('message').then(() => {
            trackEvent('finance_lead_consent', {
                ...trackingData,
                touch_point_button: event.target.checked ? 'tick' : 'untick',
            });
        });
        onChange(event.target.checked);
    };

    return (
        <>
            <Container>
                <Heading variant="bold">{t('frontend.ad.contact-form.title-finance-lead-remt-615')}</Heading>
                <CheckboxWrapper>
                    <FormControl
                        inputComponent={CheckboxInput}
                        labelComponent={CheckboxLabel}
                        inputProps={{
                            ...register('financeLead'),
                            dataAttributes: { testid: 'contact-form-financeLead' },
                            onChange: handleChange,
                        }}
                    />
                    <P4>
                        <span>{t(LABEL_VARIANTS[labelVariant])}</span>
                        {shouldUsePopover ? (
                            <StyledPopover
                                appendTo="parent"
                                placement="left-end"
                                horizontalOffset={184}
                                content={FinanceLeadMoreInfo}
                            >
                                <span data-testid="contact-form-financeLead-desktop-icon" data-icon="question-circle">
                                    <StyledQuestionMark size="icon-small" />
                                </span>
                            </StyledPopover>
                        ) : (
                            <span data-testid="contact-form-financeLead-mobile-icon">
                                <StyledQuestionMark
                                    size="icon-small"
                                    onClick={(): void => setShouldShowExpandableMoreInfo((state) => !state)}
                                />
                            </span>
                        )}
                    </P4>
                </CheckboxWrapper>
            </Container>
            {shouldShowExpandableMoreInfo ? <FinanceLeadMoreInfo shouldShowAsExpandableElement /> : null}
        </>
    );
};
