import { CONTACT_FORM_DATA_STORAGE_KEY } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormStorage';
import type { ContactFormType } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { useMessageSubjectOptions } from '@domains/ad/nexusComponents/ContactForm/hooks/useMessageSubjectOptions';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { parsePhoneNumberWithCountryCode } from '@domains/shared/components/PhoneInput/helpers';
import { getStorageItemValue } from '@domains/shared/helpers/storage';
import type { LazyUser } from '@type/user/lazyUser';
import { useEffect, useState } from 'react';

export interface UseDefaultFormDataProps {
    user: LazyUser | null | undefined;
    formType: ContactFormType;
}

export const useDefaultFormData = ({ user, formType }: UseDefaultFormDataProps): AdContactForm => {
    const messageOptions = useMessageSubjectOptions();
    const messageSubjectOptions = messageOptions.allOptions;
    const defaultMessage = ''; // we set default message in MessageField using 'watch' listener
    const defaultMessageSubject = formType === 'ask-for-price' ? messageSubjectOptions[2] : messageSubjectOptions[0];

    const [savedData, setSavedData] = useState<AdContactForm>({
        name: '',
        email: '',
        phone: '',
        countryCode: parsePhoneNumberWithCountryCode('', false).countryCode || '',
        messageSubject: defaultMessageSubject,
        message: defaultMessage,
        financeLead: false,
    });

    useEffect(() => {
        if (user) {
            setSavedData({
                name: '',
                email: user.email,
                phone: '',
                countryCode: parsePhoneNumberWithCountryCode('', false).countryCode || '',
                messageSubject: defaultMessageSubject,
                message: defaultMessage,
                financeLead: false,
            });

            return;
        }

        const prefilledContactData = getStorageItemValue(CONTACT_FORM_DATA_STORAGE_KEY) as AdContactForm;
        const phoneWithCountryCode = parsePhoneNumberWithCountryCode(prefilledContactData?.phone, false);
        setSavedData({
            name: prefilledContactData?.name || '',
            email: prefilledContactData?.email || '',
            phone: phoneWithCountryCode.phoneNumber,
            countryCode: phoneWithCountryCode.countryCode || '',
            messageSubject: defaultMessageSubject,
            message: defaultMessage,
            financeLead: false,
        });
    }, [defaultMessage, defaultMessageSubject, user]);

    return savedData;
};
