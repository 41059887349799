import { AdministratorDataInfo } from '@domains/ad/nexusComponents/ContactForm/components/AdministratorDataInfo/AdministratorDataInfo';
import {
    Container,
    StyledDivider,
    StyledForm,
    StyledSubmitButton,
} from '@domains/ad/nexusComponents/ContactForm/components/ContactFormContent/ContactFormContent.theme';
import { FinanceLeadField } from '@domains/ad/nexusComponents/ContactForm/components/FinanseLeadField/FinanceLeadField';
import { MessageField } from '@domains/ad/nexusComponents/ContactForm/components/MessageField/MessageField';
import { PhoneField } from '@domains/ad/nexusComponents/ContactForm/components/PhoneField/PhoneField';
import { PreviousContactInfo } from '@domains/ad/nexusComponents/ContactForm/components/PreviousContactInfo/PreviousContactInfo';
import { SellerInfo } from '@domains/ad/nexusComponents/ContactForm/components/SellerInfo/SellerInfo';
import { TextField } from '@domains/ad/nexusComponents/ContactForm/components/TextField/TextField';
import { CONTACT_FORM_ERROR_SNACKBAR_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormSnackbars';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { useDefaultFormData } from '@domains/ad/nexusComponents/ContactForm/hooks/useDefaultFormData';
import { useSendMessage } from '@domains/ad/nexusComponents/ContactForm/hooks/useSendMessage';
import type { Ad } from '@domains/ad/types/Ad';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { EMAIL_REGULAR_EXPRESSION } from '@domains/shared/consts/regularExpressions';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import Warning from '@nexus/lib-react/dist/core/Icon/icons/default/Warning';
import { Snackbar as ErrorSnackbar } from '@nexus/lib-react/dist/core/Snackbar';
import type { JSX } from 'react';
import { useContext } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface Props {
    ad: Ad;
    showAsModal?: boolean;
}

export const ContactFormContent = ({ ad, showAsModal }: Props): JSX.Element => {
    const { user } = useLazyUser();

    const [t] = useTranslations();
    const { trackingData, setState, formType } = useContext(ContactFormContext);
    const {
        featureFlags: { isFinanceCheckboxEnabled },
    } = useSiteSettings();
    const { isDesktop } = useContext(RWDContext);

    const defaultValues = useDefaultFormData({ user, formType });
    const formMethods = useForm<AdContactForm>({ defaultValues });
    const { handleSubmit } = formMethods;
    const { onFormSubmit, shouldDisableSubmitButton } = useSendMessage({ handleSubmit, trackingData, ad, setState });

    const emailValue = defaultValues.email;

    const nameFieldErrorMessage = t('frontend.ad.contact-form.name-required-error');
    const nameFieldValidationRules = {
        required: nameFieldErrorMessage,
        minLength: { value: 1, message: nameFieldErrorMessage },
        maxLength: { value: 100, message: nameFieldErrorMessage },
    };

    const emailFieldErrorMessage = t('frontend.ad.contact-form.email-required-error');
    const errorFieldValidationRules = {
        required: user?.email ? false : emailFieldErrorMessage,
        pattern: {
            value: EMAIL_REGULAR_EXPRESSION,
            message: emailFieldErrorMessage,
        },
    };

    const isAdvertToSell = [101, 201].includes(ad.category.id); // apartment or house to sale
    const shouldShowFinanceLead = isFinanceCheckboxEnabled && isAdvertToSell;

    const shouldUsePopoverForTooltip = !!isDesktop && !showAsModal;

    const submitButton = (
        <StyledSubmitButton
            type="submit"
            data-testid="frontend.ad.contact-form.submit"
            data-cy="contact-form.submit-button"
            disabled={shouldDisableSubmitButton}
        >
            {t('frontend.ad.contact-form.send-message')}
        </StyledSubmitButton>
    );

    return (
        <Container showAsModal={showAsModal}>
            <PreviousContactInfo adId={ad.id} />
            <SellerInfo ad={ad} trackingData={trackingData} />
            <FormProvider {...formMethods}>
                <StyledForm onSubmit={handleSubmit(onFormSubmit)}>
                    <TextField
                        name="name"
                        validationRules={nameFieldValidationRules}
                        defaultValue={defaultValues.name}
                        trackingData={trackingData}
                    />
                    <TextField
                        name="email"
                        validationRules={errorFieldValidationRules}
                        defaultValue={emailValue}
                        trackingData={trackingData}
                        shouldDisableInput={!!user?.email}
                    />
                    <PhoneField
                        defaultPhoneValue={defaultValues.phone}
                        defaultCountryCodeValue={defaultValues.countryCode}
                        trackingData={trackingData}
                        advertSource={ad.source}
                    />
                    <StyledDivider variant="secondary-light" />
                    <MessageField ad={ad} />
                    {shouldShowFinanceLead ? (
                        <FinanceLeadField trackingData={trackingData} shouldUsePopover={shouldUsePopoverForTooltip} />
                    ) : null}
                    <AdministratorDataInfo shouldUsePopover={shouldUsePopoverForTooltip} />
                    {submitButton}
                </StyledForm>
            </FormProvider>
            <ErrorSnackbar
                id={CONTACT_FORM_ERROR_SNACKBAR_ID}
                content={t('frontend.ad.contact-form.submit-error')}
                variant="error"
                position="bottom-center"
                prefixIcon={Warning}
            />
        </Container>
    );
};
