import type { Ad } from '@domains/ad/types/Ad';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';

const MESSAGES: Record<string, string> = {
    more_info_101: 'frontend.ad.contact-form.more-info-message-sale-flat',
    more_info_102: 'frontend.ad.contact-form.more-info-message-rent-flat',
    more_info_201: 'frontend.ad.contact-form.more-info-message-sale-house',
    more_info_202: 'frontend.ad.contact-form.more-info-message-rent-house',
    more_info_302: 'frontend.ad.contact-form.more-info-message-rent-room',
    more_info_401: 'frontend.ad.contact-form.more-info-message-sale-plot',
    more_info_402: 'frontend.ad.contact-form.more-info-message-rent-plot',
    more_info_501: 'frontend.ad.contact-form.more-info-message-sale-shop',
    more_info_502: 'frontend.ad.contact-form.more-info-message-rent-shop',
    more_info_601: 'frontend.ad.contact-form.more-info-message-sale-warehouse',
    more_info_602: 'frontend.ad.contact-form.more-info-message-rent-warehouse',
    more_info_701: 'frontend.ad.contact-form.more-info-message-sale-garage',
    more_info_702: 'frontend.ad.contact-form.more-info-message-rent-garage',

    schedule_visit_101: 'frontend.ad.contact-form.schedule-visit-message-sale-flat',
    schedule_visit_102: 'frontend.ad.contact-form.schedule-visit-message-rent-flat',
    schedule_visit_201: 'frontend.ad.contact-form.schedule-visit-message-sale-house',
    schedule_visit_202: 'frontend.ad.contact-form.schedule-visit-message-rent-house',
    schedule_visit_302: 'frontend.ad.contact-form.schedule-visit-message-rent-room',
    schedule_visit_401: 'frontend.ad.contact-form.schedule-visit-message-sale-plot',
    schedule_visit_402: 'frontend.ad.contact-form.schedule-visit-message-rent-plot',
    schedule_visit_501: 'frontend.ad.contact-form.schedule-visit-message-sale-shop',
    schedule_visit_502: 'frontend.ad.contact-form.schedule-visit-message-rent-shop',
    schedule_visit_601: 'frontend.ad.contact-form.schedule-visit-message-sale-warehouse',
    schedule_visit_602: 'frontend.ad.contact-form.schedule-visit-message-rent-warehouse',
    schedule_visit_701: 'frontend.ad.contact-form.schedule-visit-message-sale-garage',
    schedule_visit_702: 'frontend.ad.contact-form.schedule-visit-message-rent-garage',

    suggest_price_101: 'frontend.ad.contact-form.suggest-price-message-sale-flat',
    suggest_price_102: 'frontend.ad.contact-form.suggest-price-message-rent-flat',
    suggest_price_201: 'frontend.ad.contact-form.suggest-price-message-sale-house',
    suggest_price_202: 'frontend.ad.contact-form.suggest-price-message-rent-house',
    suggest_price_302: 'frontend.ad.contact-form.suggest-price-message-rent-room',
    suggest_price_401: 'frontend.ad.contact-form.suggest-price-message-sale-plot',
    suggest_price_402: 'frontend.ad.contact-form.suggest-price-message-rent-plot',
    suggest_price_501: 'frontend.ad.contact-form.suggest-price-message-sale-shop',
    suggest_price_502: 'frontend.ad.contact-form.suggest-price-message-rent-shop',
    suggest_price_601: 'frontend.ad.contact-form.suggest-price-message-sale-warehouse',
    suggest_price_602: 'frontend.ad.contact-form.suggest-price-message-rent-warehouse',
    suggest_price_701: 'frontend.ad.contact-form.suggest-price-message-sale-garage',
    suggest_price_702: 'frontend.ad.contact-form.suggest-price-message-rent-garage',
};

interface Arguments {
    ad: Ad;
    messageSubjectType?: string;
    t: Translator;
}

export const getDefaultMessage = ({ ad, messageSubjectType, t }: Arguments): string => {
    if (messageSubjectType === 'other') {
        return '';
    }

    if (messageSubjectType === 'ask_for_price') {
        return t('frontend.ad.contact-form.ask-about-price');
    }

    const message = MESSAGES[`${messageSubjectType}_${ad.category.id}`];

    return message ? t(message, { advertId: `${ad.id}` }) : t('frontend.ad.contact-form.default-message');
};
