import { CONTACT_FORM_ERROR_SNACKBAR_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormSnackbars';
import type { ContactFormState } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { SEND_MESSAGE_MUTATION } from '@domains/ad/nexusComponents/ContactForm/graphql/SendMessageMutation';
import { getClearedPhoneNumber } from '@domains/ad/nexusComponents/ContactForm/helpers/getClearedPhoneNumber';
import { prefillContactData } from '@domains/ad/nexusComponents/ContactForm/helpers/prefillContactData';
import { updateSavedContactDates } from '@domains/ad/nexusComponents/ContactForm/helpers/updateSavedContactDates';
import { useAdditionalFinanceParameters } from '@domains/ad/nexusComponents/ContactForm/hooks/useAdditionalFinanceParameters';
import type { Ad } from '@domains/ad/types/Ad';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { logError } from '@domains/shared/helpers/logger';
import { CurrentUserContext } from '@lib/currentUser';
import { useTracking } from '@lib/tracking/useTracking';
import { useSnackbarState } from '@nexus/lib-react/dist/core/Snackbar';
import { type Dispatch, type SetStateAction, useContext, useState } from 'react';
import type { UseFormHandleSubmit } from 'react-hook-form';
import { useMutation } from 'urql';

interface Arguments {
    handleSubmit: UseFormHandleSubmit<AdContactForm>;
    setState: Dispatch<SetStateAction<ContactFormState>>;
    trackingData: Record<string, unknown>;
    ad: Ad;
}

interface Result {
    onFormSubmit: () => Promise<void>;
    shouldDisableSubmitButton: boolean;
}

export const useSendMessage = ({ handleSubmit, trackingData, ad, setState }: Arguments): Result => {
    const { trackEvent } = useTracking();
    const user = useContext(CurrentUserContext);
    const [shouldDisableSubmitButton, setShouldDisableSubmitButton] = useState(false);
    const [, sendMessage] = useMutation(SEND_MESSAGE_MUTATION);
    const { openSnackbar: openErrorSnackbar } = useSnackbarState(CONTACT_FORM_ERROR_SNACKBAR_ID);
    const additionalFinanceParameters = useAdditionalFinanceParameters();

    const onFormSubmit = handleSubmit((formData: AdContactForm) => {
        const { name, email, phone, countryCode, messageSubject, message, financeLead } = formData;

        trackEvent('reply_message_click', trackingData);

        const additionalVariables = financeLead ? additionalFinanceParameters : {};

        const details = {
            name,
            email: email || user?.email || '',
            phone: getClearedPhoneNumber(countryCode, phone),
            text: `[${messageSubject.value}] ${message}`,
            advertId: ad.id,
            financeLead: !!financeLead,
            city: ad.location.address.city?.name,
            ...additionalVariables,
        };

        setShouldDisableSubmitButton(true);
        sendMessage(details)
            .then(({ error, data }) => {
                if (error || data.sendMessage.__typename !== 'MessageResponse') {
                    openErrorSnackbar();
                    logError('Unable to send Ad Contact Form.', { error });
                    trackEvent('reply_message_sent_error', trackingData);

                    if (financeLead) {
                        trackEvent('finance_lead_form_error', trackingData);
                    }

                    return;
                }

                if (financeLead) {
                    data.sendMessage.financeLeadResponse?.success
                        ? trackEvent('finance_lead_form_sent', trackingData)
                        : trackEvent('finance_lead_form_error', trackingData);
                }

                const { name, email, phone } = details;
                prefillContactData(user, { name, email, phone });

                trackEvent('reply_message_sent', {
                    ...trackingData,
                    finance_lead_consent: details.financeLead ? 'tick' : 'untick',
                });
                updateSavedContactDates(ad.id);
                setState('success-view');
            })
            .catch((error) => {
                logError('Failed sending the message', { error });
                openErrorSnackbar();
                trackEvent('reply_message_sent_error', trackingData);

                if (financeLead) {
                    trackEvent('finance_lead_form_error', trackingData);
                }
            })
            .finally(() => {
                setShouldDisableSubmitButton(false);
            });
    });

    return { onFormSubmit, shouldDisableSubmitButton };
};
