import { SITE_CONFIG } from '@config/siteConfig';
import { SellerAvatar } from '@domains/ad/nexusComponents/ContactForm/components/SellerAvatar/SellerAvatar';
import { getSellerTypeLabel } from '@domains/ad/nexusComponents/ContactForm/helpers/getSellerTypeLabel';
import { PhoneNumber } from '@domains/ad/nexusComponents/PhoneNumber/PhoneNumber';
import type { Ad } from '@domains/ad/types/Ad';
import { usePhoneNumber } from '@domains/shared/hooks/usePhoneNumber/usePhoneNumber';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';

import { Container, SellerName } from './SellerInfo.theme';

interface Props {
    ad: Ad;
    trackingData: Record<string, unknown>;
}

export const SellerInfo = ({ ad, trackingData }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { country, countryCode: nationalCountryCode } = SITE_CONFIG.phoneNumbers;

    const { id, contactDetails } = ad;

    const { phoneNumber } = usePhoneNumber({
        number: contactDetails.phones?.[0] ?? '',
        nationalCountryCode,
        country,
    });

    const sellerType = t(getSellerTypeLabel(ad));

    return (
        <Container>
            <SellerAvatar url={contactDetails.imageUrl} />
            <div>
                <SellerName variant="bold">{contactDetails.name}</SellerName>
                <P3>{sellerType}</P3>
                {phoneNumber ? <PhoneNumber adId={id} trackingData={trackingData} phoneNumber={phoneNumber} /> : null}
            </div>
        </Container>
    );
};
