import { SITE_CONFIG } from '@config/siteConfig';
import { StyledGdprNote } from '@domains/ad/nexusComponents/ContactForm/components/AdministratorDataInfo/AdministratorDataInfo.theme';
import { transformGdprDetails } from '@domains/shared/components/GdprNote/helpers/transformGdprDetails';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

interface Props {
    shouldUsePopover: boolean;
}

export const AdministratorDataInfo = ({ shouldUsePopover }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { privacyPolicyLink, termsAndConditionsLink } = SITE_CONFIG;

    return (
        <StyledGdprNote
            data-cy="contact-form.gdpr-note"
            intro={t('frontend.shared.contact-form.gdpr-administrator')}
            details={transformGdprDetails({
                description: t('frontend.shared.contact-form.gdpr-privacy-policy-new'),
                privacyPolicyLink,
                termsAndConditionsLink,
            })}
            shouldUsePopover={shouldUsePopover}
        />
    );
};
