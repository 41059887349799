import { PREVIOUS_CONTACT_STORAGE_KEY } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormStorage';
import { getStorageItemValue, setStorageItemValue } from '@domains/shared/helpers/storage';

export const updateSavedContactDates = (adId: number): void => {
    const savedData = getStorageItemValue(PREVIOUS_CONTACT_STORAGE_KEY) || {};
    setStorageItemValue(PREVIOUS_CONTACT_STORAGE_KEY, {
        ...savedData,
        [adId]: Date.now(),
    });
};
