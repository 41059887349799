import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import QuestionMark from '@nexus/lib-react/dist/core/Icon/icons/default/QuestionMark';
import { Popover } from '@nexus/lib-react/dist/core/Popover';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    margin-top: ${theme.space['16']};
    padding: ${theme.space['16']};
    background-color: ${theme.colors['background-brand-alternative-light']};
`;

export const Heading = styled(P3)`
    margin-bottom: ${theme.space['8']};
`;

export const CheckboxWrapper = styled.div`
    display: flex;
    gap: 8px;
`;

export const CheckboxLabel = styled.label`
    margin: 0;
`;

export const StyledPopover = styled(Popover)`
    --bgColor: ${theme.colors['background-global-inverse']};

    padding: ${theme.space['8']} ${theme.space['16']};
`;

export const StyledQuestionMark = styled(QuestionMark)`
    margin: 0 0 -4px 2px;
`;
