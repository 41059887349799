import type { CSSProperties } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div<{ shouldShowAsExpandableElement?: boolean }>`
    max-width: 380px;
    color: ${({ shouldShowAsExpandableElement }): CSSProperties['color'] =>
        shouldShowAsExpandableElement ? theme.colors.$07_black : theme.colors.$01_white};
    font-family: ${theme.fonts.family};
    font-size: ${theme.fontSizes.p4};

    & a {
        color: ${({ shouldShowAsExpandableElement }): CSSProperties['color'] =>
            shouldShowAsExpandableElement ? theme.colors.$07_black : theme.colors.$01_white};
    }

    & ul {
        padding-left: ${theme.space['24']};
    }
`;
