import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { FormControlLabel } from '@nexus/lib-react/dist/core/FormControl';
import { TextInput } from '@nexus/lib-react/dist/core/TextInput';

const HIDE_EMPTY_DIV = css`
    &:empty {
        display: none;
    }
`;

export const CustomLabel = styled(FormControlLabel)`
    ${HIDE_EMPTY_DIV}

    & ~ div {
        ${HIDE_EMPTY_DIV}
    }
`;

export const StyledTextInput = styled(TextInput)`
    width: 100%;
    margin-bottom: ${theme.space['8']};
`;
