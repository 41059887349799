import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { ErrorMessage } from '@domains/ad/nexusComponents/ContactForm/components/ContactFormContent/ContactFormContent.theme';
import {
    CustomLabel,
    InputsWraper,
    StyledCountryCodeInput,
    StyledPhoneNumberInput,
} from '@domains/ad/nexusComponents/ContactForm/components/PhoneField/PhoneField.theme';
import { getPhoneNumberValidationStatus } from '@domains/ad/nexusComponents/ContactForm/helpers/getPhoneNumberValidationStatus';
import { isContactFormPhoneNumberRequired } from '@domains/ad/nexusComponents/ContactForm/helpers/isContactFormPhoneNumberRequired';
import type { AdContactForm } from '@domains/ad/types/contactForm';
import { VALID_COUNTRY_CODE_REGEX } from '@domains/shared/components/PhoneInput/helpers';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { FormControl } from '@nexus/lib-react/dist/core/FormControl';
import type { JSX } from 'react';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

interface Props {
    defaultPhoneValue: string;
    defaultCountryCodeValue: string;
    trackingData: Record<string, unknown>;
    advertSource: string;
}

export const PhoneField = ({
    defaultPhoneValue,
    defaultCountryCodeValue,
    trackingData,
    advertSource,
}: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isVariantEnabled } = useExperiments();
    const {
        register,
        trigger,
        control,
        watch,
        setValue,
        formState: { errors, touchedFields },
    } = useFormContext<AdContactForm>();

    const phoneNumberWatched = watch('phone');
    const countryCodeWatched = watch('countryCode');

    const handlePhoneNumberBlur = (): void => {
        trigger('phone').then(() => {
            trackEvent(errors.phone ? 'reply_message_phone_error' : 'reply_message_phone_valid', trackingData);
        });
    };

    const isFinanceLeadChecked = useWatch({
        control,
        name: 'financeLead',
    });

    useEffect(() => {
        if (touchedFields.phone) {
            trigger('phone');
        }
    }, [isFinanceLeadChecked, touchedFields, trigger]);

    useEffect(() => {
        if (defaultPhoneValue) {
            setValue('phone', defaultPhoneValue);
        }
        if (defaultCountryCodeValue) {
            setValue('countryCode', defaultCountryCodeValue);
        }
    }, [defaultPhoneValue, defaultCountryCodeValue, setValue]);

    const isEURE28875VariantB = isVariantEnabled(EXPERIMENT.eure28875, 'b');
    const isPhoneFieldRequired = isContactFormPhoneNumberRequired({
        isEURE28875VariantB,
        isFinanceLeadChecked,
        advertSource,
    });

    return (
        <>
            <InputsWraper>
                <FormControl
                    labelComponent={CustomLabel}
                    inputComponent={StyledCountryCodeInput}
                    inputProps={{
                        ...register('countryCode', {
                            required: isPhoneFieldRequired
                                ? t('frontend.shared.phone-input.error-country-code')
                                : false,
                            pattern: {
                                value: VALID_COUNTRY_CODE_REGEX,
                                message: t('frontend.shared.phone-input.error-country-code'),
                            },
                        }),
                        value: countryCodeWatched,
                        autoComplete: 'countryCode',
                        dataAttributes: {
                            testid: 'frontend.ad.contact-form.field-country-code',
                            cy: 'phone-input.country',
                        },
                    }}
                    aria-label={t('frontend.ad.contact-form.field-placeholder-phone')}
                />
                <FormControl
                    labelComponent={CustomLabel}
                    inputComponent={StyledPhoneNumberInput}
                    inputProps={{
                        ...register('phone', {
                            validate: (value) => getPhoneNumberValidationStatus(t, isPhoneFieldRequired, value),
                        }),
                        placeholder: `${t('frontend.ad.contact-form.field-placeholder-phone')}${isPhoneFieldRequired ? '*' : ''}`,
                        validationStatus: errors?.phone ? 'hasError' : undefined,
                        value: phoneNumberWatched,
                        onBlur: handlePhoneNumberBlur,
                        dataAttributes: { testid: 'frontend.ad.contact-form.field-phone', cy: 'phone-input.phone' },
                    }}
                    aria-label={t('frontend.ad.contact-form.field-placeholder-phone')}
                />
            </InputsWraper>
            {errors?.countryCode?.message ? <ErrorMessage>{errors.countryCode.message}</ErrorMessage> : null}
            {errors?.phone?.message ? <ErrorMessage>{errors.phone.message}</ErrorMessage> : null}
        </>
    );
};
