import { VALID_PHONE_NUMBER_REGEX } from '@domains/shared/components/PhoneInput/helpers';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';

export const getPhoneNumberValidationStatus = (
    t: Translator,
    isPhoneFieldRequired: boolean,
    phone: string = '',
): string | undefined => {
    if (!phone && isPhoneFieldRequired) {
        return t('frontend.ad.contact-form.phone-required-error');
    }

    if (!phone && !isPhoneFieldRequired) {
        return undefined;
    }

    const value = phone.replace(/[ -]/g, '');

    return VALID_PHONE_NUMBER_REGEX.test(value) ? undefined : t('frontend.shared.phone-input.error-format');
};
