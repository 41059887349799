import {
    Container,
    Heading,
    Icon,
    SaveAdInfo,
    SaveAdWrapper,
} from '@domains/ad/nexusComponents/ContactForm/components/ContactFormSuccess/ContactFormSuccess.theme';
import { CONTACT_FORM_DIALOG_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormDialogId';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import successfulSubmitIcon from '@domains/shared/assets/successfulSubmit.svg?url';
import { AddToFavorites } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import type { JSX } from 'react';
import { useContext, useState } from 'react';

interface Props {
    id: number;
}

export const ContactFormSuccess = ({ id }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { setState } = useContext(ContactFormContext);
    const { checkIfAdIsSaved } = useContext(SavedAdsContext);
    const isAdSaved = checkIfAdIsSaved(id);
    const [isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen] = useState(false);
    const { closeDialog } = useDialogState(CONTACT_FORM_DIALOG_ID);

    const closeSuccessView = (): void => {
        setState('form');
        closeDialog();
    };

    return (
        <Container data-cy="similar-ads-contact-form">
            <Icon src={successfulSubmitIcon} alt="Message has been sent" />
            <Heading>{t('frontend.ad.contact-form.submit-success')}</Heading>
            {isAdSaved ? null : (
                <SaveAdWrapper>
                    <SaveAdInfo>{t('frontend.ad.subscribe-ad-section.save-ad-text')}</SaveAdInfo>
                    <AddToFavorites
                        id={id}
                        showAsNexusButton={true}
                        isRedirectToLoginModalOpen={isRedirectToLoginModalOpen}
                        setIsRedirectToLoginModalOpen={setIsRedirectToLoginModalOpen}
                        placement="ad-page"
                        label={t('frontend.atoms.ad-subscribe-button.save-advert')}
                        onStateChange={closeSuccessView}
                    />
                </SaveAdWrapper>
            )}
            <Button variant="tertiary" onClick={closeSuccessView} data-cy="similar-ads-contact-form-close-button">
                {t('frontend.ad.contact-form.submit-success-skip-button')}
            </Button>
        </Container>
    );
};
