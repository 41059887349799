import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    display: flex;
    gap: ${theme.space['16']};
`;

export const SellerName = styled(P2)`
    margin-bottom: ${theme.space['4']};
`;
