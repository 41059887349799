import { pipe } from '@domains/shared/helpers/pipe';
import { removeAttributes } from '@domains/shared/helpers/removeAttributes';
import { stripTags } from '@domains/shared/helpers/stripTags';

const stripNotAllowedTags = (input: string): string => {
    return stripTags(input, ['p', 'ul', 'ol', 'li', 'a', 'b']);
};

const stripNotAllowedAttributes = (input: string): string => {
    return removeAttributes(input, ['href', 'target']);
};

export const parseFinanceLeadDescription = pipe(stripNotAllowedTags, stripNotAllowedAttributes);
